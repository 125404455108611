<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    :width="800"
    class="fill-assignment"
  >
    <template #title>
      <strong class="modal-title"> 选择用户 </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <div class="select-reviewer">
      <div class="title">当前人员</div>
      <a-table
        :data-source="personInfo"
        :columns="personColumns"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
      >
      </a-table>
    </div>
    <a-form :model="formData" :rules="formRules" ref="formRef" class="org-form">
      <div class="form-part">
        <div class="review-info">选择需要复制的人员</div>
        <a-form-item label="" name="personId">
          <a-select
            ref="selectPersonRef"
            v-model:value="formData.personId"
            placeholder="请选择"
            show-search
            :filterOption="filterOption"
          >
            <a-select-option
              v-for="item in personOps"
              :value="item.id"
              :key="item.id"
            >
              {{ item.fullName + " " + item.email }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </a-form>
    <div class="select-reviewer">
      <div class="title">选中人员预览</div>
      <a-table
        :data-source="choosePersonList"
        :columns="columns"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
      >
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "CopyDataPerms",
  props: {
    title: String,
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();

    const selectPersonRef = ref();

    const visible = ref(false);
    const formRef = ref();
    const formData = ref({
      personId: undefined,
    });
    const formRules = {
      personId: [
        {
          required: true,
          message: "Please select Person",
          trigger: "change",
        },
      ],
    };

    const personOps = ref([]);

    const getEMOptions = () => {
      $EventBus.on("getAllStaffs", (allStaffs) => {
        personOps.value = allStaffs;
      });
    };

    const choosePersonList = computed(() =>
      personOps.value.filter((item) => formData.value.personId == item.id)
    );
    choosePersonList.value.push();

    const personInfo = ref([]);

    const copyClick = (row) => {
      personInfo.value[0] = row;
      formData.value.roleName = row.roleName;
      formData.value.costCtr = row.costCtr;
      formData.value.costCenter = row.costCenter;
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
      formData.value = {
        personIdList: [],
      };
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          originUserId: personInfo.value[0].id,
          newUserId: formData.value.personId,
          roleType: personInfo.value[0].role,
        };
        $api.copyDataPerm(params).then(() => {
          closeModal();
          ctx.emit("refresh-table");
          message.success("操作成功");
        });
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const personColumns = [
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 70,
        align: "center",
      },
    ];

    const columns = [
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 70,
        align: "center",
      },
    ];

    const tableHeight = ref(0);

    onMounted(() => {
      const clientHeight = document.body.clientHeight;
      tableHeight.value = clientHeight * 0.4;
      getEMOptions();
    });

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      closeModal,
      submitModal,
      visible,
      formData,
      formRef,
      formRules,
      personOps,
      choosePersonList,
      getRefsAff,
      copyClick,
      selectPersonRef,
      filterOption,
      columns,
      personColumns,
      tableHeight,
      personInfo,
    };
  },
});
</script>

<style lang="less">
.fill-assignment {
  width: 50vw;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }

    .ant-table-body {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 13px;
        height: 13px;
      }

      &::-webkit-scrollbar-thumb {
        width: 13px;
        height: 13px;
        background: #d0e8ff;
        border: 1px solid #d0e8ff;
        border-radius: 5px;
      }
    }
  }

  .footerBtn {
    text-align: center;
    margin: 5px;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin: 10px 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .select-reviewer {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  .review-info {
    margin-bottom: 10px;
  }
}
</style>
