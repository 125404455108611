<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    class="fill-assignment"
  >
    <template #title>
      <strong class="modal-title"> 选择Cost Ctr </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form :model="formData" :rules="formRules" ref="formRef" class="org-form">
      <div class="form-part">
        <div class="review-info">IdNumber: {{ formData.idNumber }}</div>
        <div class="review-info">姓名: {{ formData.fullName }}</div>
        <div class="review-info">角色: {{ formData.role }}</div>
        <a-form-item label="" name="costCtrList">
          <div class="select-org">
            <a-select
              ref="selectPersonRef"
              v-model:value="formData.costCtrList"
              placeholder="请选择"
              show-search
              :filterOption="filterOption"
              :show-arrow="false"
              mode="multiple"
              :maxTagCount="1"
              :listHeight="190"
            >
              <a-select-option
                v-for="item in costCtrOps"
                :value="item.costCtr"
                :key="item.costCtr"
              >
                {{ item.costCenter }}
              </a-select-option>
            </a-select>
            <a-button class="select-all-btn" type="primary" @click="selectAll">全选</a-button>
          </div>
        </a-form-item>
      </div>
    </a-form>
    <div class="select-reviewer">
      <div class="title">选中Cost Ctr预览</div>
      <a-table
        :data-source="chooseCostCtrList"
        :columns="columns"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
      >
        <template #operation="{ record }">
          <a-space>
            <span class="operationLink underline" @click="delClick(record)"> 删除 </span>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "EditDataPerms",
  props: {
    title: String,
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();

    const selectPersonRef = ref();

    const visible = ref(false);
    const formRef = ref();
    const formData = ref({
      costCtrList: [],
    });
    const formRules = {
      costCtrList: [
        {
          required: true,
          message: "Please select Cost Ctr",
          trigger: "change",
          type: "array",
        },
      ],
    };

    const costCtrOps = ref([]);

    const getEMOptions = () => {
      $EventBus.on("getCtrOps", (ctrOps) => {
        costCtrOps.value = ctrOps;
      });
    };

    const getCostCtrList = () => {
      const params = {
        userId: formData.value.id,
        roleType: formData.value.role,
      };
      $api.getCostCtrByPersonAndRoleList(params).then((res) => {
        formData.value.costCtrList = res.data;
      });
    };

    const chooseCostCtrList = computed(() =>
      costCtrOps.value.filter(
        (item) => formData.value.costCtrList.indexOf(item.costCtr) !== -1
      )
    );

    const editClick = (row) => {
      formData.value.id = row.id;
      formData.value.idNumber = row.idNumber;
      formData.value.fullName = row.fullName;
      formData.value.role = row.role;
      getCostCtrList();
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
      formData.value = {
        costCtrList: [],
      };
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          userId: formData.value.id,
          roleType: formData.value.role,
          costCtrList: formData.value.costCtrList,
        };
        $api.savePerms(params).then(() => {
          closeModal();
          ctx.emit("refresh-table");
          message.success("操作成功");
        });
      });
    };

    const selectAll = () => {
      formData.value.costCtrList = costCtrOps.value.map(item => item.costCtr)
    }

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const columns = [
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 70,
        align: "center",
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: 80,
        align: "center",
        ellipsis: true,
        slots: {
          customRender: "operation",
        },
      },
    ];

    const delClick = (record) => {
      const index = formData.value.costCtrList.indexOf(record.costCtr);
      formData.value.costCtrList.splice(index, 1);
    };

    const tableHeight = ref(0);

    onMounted(() => {
      const clientHeight = document.body.clientHeight;
      tableHeight.value = clientHeight * 0.3;
      getEMOptions();
    });

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      closeModal,
      submitModal,
      visible,
      formData,
      formRef,
      formRules,
      costCtrOps,
      chooseCostCtrList,
      getRefsAff,
      editClick,
      selectPersonRef,
      filterOption,
      columns,
      tableHeight,
      delClick,
      selectAll
    };
  },
});
</script>

<style lang="less">
.fill-assignment {
  width: 50vw;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }

    .ant-table-body {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 13px;
        height: 13px;
      }

      &::-webkit-scrollbar-thumb {
        width: 13px;
        height: 13px;
        background: #d0e8ff;
        border: 1px solid #d0e8ff;
        border-radius: 5px;
      }
    }
  }

  .footerBtn {
    text-align: center;
    margin: 5px;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin: 10px 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .select-reviewer {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  .review-info {
    margin-bottom: 10px;
  }

  .select-org {
    display: flex;
  }

  .select-all-btn {
    margin-left: 10px;
  }
}
</style>
