<template>
  <section class="app-container" v-show="visible">
    <Filter @search="handleSearch"></Filter>
    <List ref="listRef" @edit-click="editClick" @copy-click="copyClick"></List>
    <edit-data-perms ref="editPersonRef" @refresh-table="refreshTable" />
    <copy-data-perms ref="copyPersonRef" @refresh-table="refreshTable" />
  </section>
</template>
<script>
import { defineComponent, onActivated, ref } from "vue";
import Filter from "./components/Filter.vue";
import List from "./components/List";
import EditDataPerms from "@/views/home/ConfigManagement/DataPerms/DataPermsByPerson/components/EditDataPerms";
import CopyDataPerms from "@/views/home/ConfigManagement/DataPerms/DataPermsByPerson/components/CopyDataPerms";
import { useGlobalPropertyHook } from "../../../../../hooks/common";
export default defineComponent({
  name: "maintainDataPermsByPerson",
  components: {
    EditDataPerms,
    CopyDataPerms,
    List,
    Filter,
  },
  setup(props, ctx) {
    const listRef = ref();
    const editPersonRef = ref();
    const copyPersonRef = ref();
    let visible = ref(true);
    const { $EventBus } = useGlobalPropertyHook();
    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    const refreshTable = () => {
      listRef.value.doSearch();
    };
    const editClick = (row) => {
      editPersonRef.value.editClick(row);
    };
    const copyClick = (row) => {
      copyPersonRef.value.copyClick(row);
    };
    $EventBus.on("refreshDataPerms", () => {
      refreshTable();
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });
    return {
      listRef,
      editPersonRef,
      copyPersonRef,
      handleSearch,
      refreshTable,
      editClick,
      copyClick,
      visible,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: calc(100% - 40px);
  margin: 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
