<template>
  <section class="top-filter">
    <div class="mt12 mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
      <a-form
        ref="formRef"
        class="form rms-form"
        layout="inline"
        :model="formState"
      >
        <a-form-item label="Email" name="email" class="form-item-inline">
          <a-select
            v-model:value="formState.email"
            placeholder="请选择"
            allowClear
            show-search
            autocomplete="off"
          >
            <a-select-option
              v-for="item in personOps"
              :value="item.email"
              :key="item.email"
            >
              {{ item.email }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="ID Number" name="idNumber" class="form-item-inline">
          <a-input
            v-model:value="formState.idNumber"
            placeholder="ID Number"
            allowClear
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="姓名" name="name" class="form-item-inline">
          <a-input
            v-model:value="formState.name"
            placeholder="姓名"
            enter-button
            allowClear
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {cloneDeep} from "lodash";

export default defineComponent({
  name: "TopFilter",
  components: {},
  emits: ["search"],
  setup(props, ctx) {
    const { $EventBus } = useGlobalPropertyHook();
    const formRef = ref();
    const personOps = ref([]);
    const getEMOptions = () => {
      $EventBus.on("getAllStaffs", (allStaffs) => {
        personOps.value = allStaffs;
      });
    };

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    const formState = reactive({
      email: undefined,
      idNumber: undefined,
      name: undefined,
    });
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    onMounted(() => {
      nextTick(doSearch);
      getEMOptions();
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      personOps,
      updateParams,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
</style>
